nav {
  opacity: 0.95;
  background-color: #191919;
  min-height: 80px;
}

body {
  background-color: #000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: inherit;
}

.screen-cover {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}

.container-fluid--no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.portfolio-container {
  background-color: #191919;
  padding: 10px;
}

.portfolio-container .portfolio-item {
  height: calc(25vw - 20px);
  width: calc(25% - 20px);
  margin: 10px;
  display: inline-block;
  padding: 0;
}

.portfolio-container .portfolio-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.portfolio-container .portfolio-text {
  background-color: #fff;
  height: 100%;
  transition: all 0.3s;
  opacity: 0;
  font-size: 1.1em;
  cursor: pointer;
}

.portfolio-container .portfolio-text:hover,
.portfolio-container .portfolio-text.active {
  opacity: 0.8;
  font-size: 1em;
}

@media (max-width: 990px) {
  .portfolio-container .portfolio-item {
    height: calc(50vw - 20px);
    width: calc(50% - 20px);
  }
}

@media (max-width: 767px) {
  .portfolio-container .portfolio-item {
    height: calc(100vw - 20px);
    width: calc(100% - 20px);
  }
}

.carousel-caption--add-shadow-to-items * {
  text-shadow: 0px 0px 10px black;
}

.bordered-top {
  border-top: 1px solid #f1f1f1;
}

.bordered-bottom {
  border-bottom: 1px solid #f1f1f1;
}

.footer {
  color: #f1f1f1;
  padding: 10px;
  font-size: 0.9rem;
}

.navbar-brand {
  cursor: default;
}

.navbar {
  color: #f1f1f1;
}

.nav-item {
  cursor: pointer;
}

.nav-item.active {
  border-bottom: 1px solid #f1f1f1;
}

.divider-horizontal {
  margin: 10px 20%;
  border-bottom: 1px solid #f1f1f1;
}

.divider-horizondal--middle-size {
  width: 60%;
}

.sample-show-hide {
  transition: all linear 0.5s;
}

.sample-show-hide.ng-hide {
  opacity: 0;
}

.custom-modal {
  display: block;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}

.custom-modal .center-content-vertically {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 100vh;
}

.custom-modal .modal-content {
  position: relative;
  background-color: black;
  margin: 30px auto;
  padding: 0;
  text-align: center;
  width: calc(100vw - 100px);
}

@media (min-width: 1500px) {
  .custom-modal .modal-content {
    width: 1400px;
  }
}

.custom-modal .close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
}

.custom-modal .item-details img {
  max-height: calc(100vh - 120px - 60px - 30px - 34px);
  max-width: 1210px;
}

.custom-modal .item-details a.disabled {
  pointer-events: none;
  cursor: default;
}

.custom-modal .item-details .zoom-overlay-container {
  position: relative;
  display: inline-block;
}

.custom-modal .item-details .zoom-overlay-container .zoom-overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.custom-modal .item-details .zoom-overlay-container .zoom-overlay .zoom-overlay-content {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 0;
  font-size: 20px;
  transition: .2s ease;
  background-color: #fff;
  opacity: 0.7;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  color: black;
  z-index: -1;
}

.custom-modal .item-details .zoom-overlay-container .zoom-overlay .zoom-overlay-content i {
  display: none;
  padding: 15px;
}

@media (max-width: 700px) {
  .custom-modal .item-details .zoom-overlay-container .zoom-overlay .zoom-overlay-content {
    width: 50px;
    z-index: 9999;
  }
  .custom-modal .item-details .zoom-overlay-container .zoom-overlay .zoom-overlay-content i {
    display: initial;
  }
}

.custom-modal .item-details .zoom-overlay-container .zoom-overlay:hover .zoom-overlay-content {
  width: 50px;
  z-index: 9999;
}

.custom-modal .item-details .zoom-overlay-container .zoom-overlay:hover .zoom-overlay-content i {
  display: initial;
}

.custom-modal .caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

.contact-info {
  font-size: 0.9rem;
}

.filters {
  background-color: black;
  font-size: 0.9rem;
  padding: 10px 15px;
}

@media (min-width: 992px) {
  .filters {
    /* Safari */
    position: sticky;
    top: 80px;
    z-index: 1;
  }
}

.filters .filters__item {
  color: #f1f1f1;
  cursor: pointer;
  opacity: 0.8;
}

.filters .filters__item:hover {
  opacity: 1;
}

.filters .filters__item.filters__item--active {
  opacity: 1;
  text-decoration: underline;
}

